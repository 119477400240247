<template>
    <div></div>
</template>
<script>
import { mapGetters } from 'vuex'
import { staffBindWxCp } from '@/js/api/staffApi.js'

export default {
    computed: { ...mapGetters(['userInfo']) },
    created() {
        if (!this.userInfo) {
            this.$message.warning('登录已过期，请重新登录')
            setTimeout(() => {
                this.$router.push('/login')
            }, 2000)
        } else if (!this.$route.query.code) {
            this.$message.warning('获取授权信息失败，请重试')
            setTimeout(() => {
                this.$router.push('/content')
            }, 2000)
        } else {
            this.staffBindWxCpApi()
        }
    },
    methods: {
        staffBindWxCpApi() {
            staffBindWxCp({
                code: this.$route.query.code,
                staffId: this.userInfo.id,
            }).then(() => {
                this.$message.success('授权成功')
                this.$router.push('/content')
            })
        },
    },
}
</script>
